html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
em,
strong,
abbr,
acronym,
blockquote,
q,
cite,
ins,
del,
dfn,
a,
div,
span,
pre,
hr,
address,
br,
b,
i,
sub,
big,
small,
tt,
table,
tr,
caption,
thead,
tbody,
tfoot,
col,
colgroup,
form,
input,
label,
textarea,
button,
fieldset,
legend,
select,
option,
ul,
ol,
li,
dl,
dt,
dd,
code,
var,
samp,
img,
object,
param,
map,
area {
  padding: 0;
  margin: 0;
  border: none;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
header,
footer,
section,
aside,
figure,
figcaption,
input {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 15px;
  vertical-align: baseline;
  background: transparent;
}
ol,
ul {
  list-style: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
input[type=button],
input[type=submit],
button {
  cursor: pointer;
}
button {
  border: 0;
}
:focus {
  outline: none;
}
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  border: none;
  outline: none;
}
input::-ms-clear {
  width: 0;
  height: 0;
}
input,
textarea {
  resize: none;
  user-select: text;
}
::-ms-clear {
  width: 0;
  height: 0;
  display: none;
}
::-ms-reveal {
  width: 0;
  height: 0;
  display: none;
}
a {
  text-decoration: none;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
input,
select,
textarea {
  border-radius: 0;
  box-shadow: none;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/HelveticaNeueCyr-Roman.woff2") format('woff2');
}
@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/HelveticaNeueCyr-Medium.woff2") format('woff2');
}
@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/HelveticaNeueCyr-Bold.woff2") format('woff2');
}
@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/HelveticaNeueCyr-Heavy.woff2") format('woff2');
}
@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 800;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/HelveticaNeueCyr-Heavy.woff2") format('woff2');
}
@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/HelveticaNeueCyr-Black.woff2") format('woff2');
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.owl-item {
  transform: translateZ(0);
}
img {
  display: block;
}
button {
  background: transparent;
}
input,
textarea {
  font-family: 'Helvetica Neue', sans-serif;
  font-weight: 400;
  -webkit-appearance: none;
  border-radius: 0;
  color: rgba(255,255,255,0.45);
}
html,
body {
  background-color: #151515;
  color: rgba(255,255,255,0.45);
  height: 100%;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
html::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 8px;
}
html::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background: #4e4e50;
  border-radius: 10px;
}
.white-body {
  background-color: #f4f4f4;
}
.white-body .footer__logo--white {
  display: none !important;
}
.white-body .footer__logo--black {
  display: block !important;
}
.white-body .footer__copyright,
.white-body .footer__text {
  color: #8f95a5;
}
.white-body .footer__content {
  border-top: 1px solid #d6d8dc !important;
}
.white-body .footer__links-title {
  color: #8f95a5;
}
* {
  margin: 0;
  padding: 0;
}
header,
footer,
section {
  max-width: 100vw;
}
html,
body {
  width: 100%;
  height: 100%;
}
body {
  max-width: 100vw;
  overflow-x: hidden;
}
section {
  padding-top: 64px;
  padding-bottom: 64px;
  margin-top: 80px;
  margin-bottom: 80px;
  position: relative;
  max-width: 100vw;
}
@media only screen and (max-width: 960px) {
  section {
    padding-top: 32px;
    padding-bottom: 32px;
    margin-top: 60px;
    margin-bottom: 60px;
  }
}
@media only screen and (max-width: 600px) {
  section {
    padding-top: 40px;
    padding-bottom: 40px;
    margin-bottom: 0;
    margin-top: 0;
  }
}
.b-wrapper {
  width: 100%;
  display: table;
  height: 100%;
  padding-top: 112px;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}
@media only screen and (max-width: 600px) {
  .b-wrapper {
    padding-top: 0;
  }
}
.content {
  display: table-row;
  width: 100%;
  height: 100%;
}
.section {
  width: 100%;
}
.hidden {
  display: none !important;
}
.container {
  max-width: 1632px;
  padding-left: 40px;
  padding-right: 40px;
  width: 100%;
}
@media only screen and (max-width: 960px) {
  .container {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media only screen and (max-width: 600px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row {
  margin-bottom: 0;
}
.col {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
header {
  margin-bottom: 60px;
}
@media only screen and (max-width: 960px) {
  header {
    margin-bottom: 0;
  }
}
span {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  font-weight: inherit;
}
strong {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  font-weight: 700;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Helvetica Neue';
  color: #fff;
  font-weight: 700;
}
h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
h6 span {
  color: #cb6ce4 !important;
}
h1 {
  font-size: 80px;
  line-height: 82px;
  letter-spacing: -3px;
  text-transform: uppercase;
  margin-bottom: 40px;
}
@media only screen and (max-width: 1440px) {
  h1 {
    font-size: 72px;
    line-height: 72px;
  }
}
@media only screen and (max-width: 960px) {
  h1 {
    font-size: 52px;
    line-height: 56px;
  }
}
@media only screen and (max-width: 720px) {
  h1 {
    font-size: 42px;
    line-height: 48px;
  }
}
@media only screen and (max-width: 600px) {
  h1 {
    margin-bottom: 32px;
  }
}
h2 {
  font-size: 64px;
  line-height: 68px;
  text-align: center;
  letter-spacing: -3px;
  text-transform: uppercase;
  margin-bottom: 24px;
}
@media only screen and (max-width: 1440px) {
  h2 {
    font-size: 48px;
    line-height: 50px;
  }
}
@media only screen and (max-width: 960px) {
  h2 {
    font-size: 40px;
    line-height: 50px;
  }
}
@media only screen and (max-width: 720px) {
  h2 {
    font-size: 36px;
    line-height: 50px;
    margin-bottom: 12px;
  }
}
h3 {
  font-size: 48px;
  line-height: 50px;
  letter-spacing: -3px;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 32px;
}
@media only screen and (max-width: 1440px) {
  h3 {
    font-size: 36px;
    line-height: 48px;
  }
}
@media only screen and (max-width: 600px) {
  h3 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 16px;
  }
}
h5 {
  font-size: 24px;
  line-height: 32px;
  color: #fff;
  margin-bottom: 32px;
}
p {
  font-size: 14px;
  line-height: 24px;
}
.btn-custom {
  padding: 13px 24px;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  transition: all 0.3s ease;
  font-weight: 700;
}
@media only screen and (max-width: 600px) {
  .btn-custom {
    font-size: 16px;
    line-height: 20px;
  }
}
.btn-custom--border {
  border: 1px solid #fff;
  background-color: transparent;
}
.btn-custom--border:hover {
  color: #01d5c9;
  border: 1px solid #01d5c9;
}
.btn-custom--red {
  color: #151515;
  background-color: #01d5c9 !important;
  border: 1px solid #01d5c9 !important;
}
.btn-custom--red:hover {
  background-color: #00e3d6 !important;
  border: 1px solid #00e3d6 !important;
}
.btn-custom--big {
  padding: 16px 24px;
}
.modal-overlay {
  background: rgba(21,21,21,0.75) !important;
  backdrop-filter: blur(8px) !important;
  opacity: 1 !important;
}
.modal {
  width: 100%;
  max-width: 556px;
  background-color: #fff;
  padding: 50px 68px;
  max-height: 95%;
  top: 50% !important;
  transform: translateY(-50%) scaleX(0.8) scaleY(0.8) !important;
}
@media only screen and (max-width: 600px) {
  .modal {
    margin-left: 15px;
    margin-right: 15px;
    width: calc(100% - 30px);
    padding-left: 14px;
    padding-right: 14px;
  }
}
.modal.open {
  top: 50% !important;
  transform: translateY(-50%) scaleX(1) scaleY(1) !important;
}
.modal .modal-content {
  padding: 0;
}
.modal__cross {
  position: absolute;
  top: 18px;
  right: 18px;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .modal__cross {
    width: 24px;
    height: 24px;
  }
}
.modal__title {
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.5px;
  color: #151515;
  margin-bottom: 32px;
}
@media only screen and (max-width: 600px) {
  .modal__title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 22px;
  }
}
.modal .modal-footer {
  padding: 0;
  background-color: transparent;
  border: none;
  border-radius: 0;
  margin-top: 16px;
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  color: #151515;
}
.modal .modal-footer a {
  color: #00e3d6;
  font-weight: 600;
}
.preloader-wrapper {
  display: none;
}
form.custom-form .input-field {
  position: relative;
  margin: 0;
  margin-bottom: 16px;
  height: 72px;
  background-color: #f8f8f8;
  display: flex;
  align-items: flex-end;
  border-radius: 12px;
}
form.custom-form .input-field .error {
  position: absolute;
  bottom: 0;
  left: 24px;
}
form.custom-form .input-field--textarea {
  height: auto;
}
form.custom-form .input-field textarea {
  height: 120px;
  background-color: transparent;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #151515;
  padding: 40px 24px;
}
form.custom-form .input-field input {
  margin-bottom: 0;
  border: none !important;
  box-shadow: none !important;
  height: 24px;
  background-color: transparent;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #151515;
  border-radius: 0;
  padding: 20px 24px;
}
form.custom-form .input-field label {
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  color: #808191 !important;
  transform: translateY(26px);
  padding-left: 24px;
}
form.custom-form .input-field label.active {
  transform: translateY(10px);
}
form.custom-form .form-error {
  position: absolute;
  bottom: 8px;
  left: 24px;
  font-size: 12px;
  line-height: 12px;
  color: #f00;
  font-weight: 600;
}
form.custom-form .checkboxes-wrap {
  margin-top: 32px;
}
form.custom-form .checkboxes-wrap .checkbox {
  margin-bottom: 8px;
  position: relative;
}
form.custom-form .checkboxes-wrap .checkbox .form-error {
  bottom: -12px;
  left: 28px;
}
form.custom-form .checkboxes-wrap .checkbox__input {
  padding-left: 28px;
  top: 0;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #151515;
}
form.custom-form .checkboxes-wrap .checkbox__input a {
  color: #00e3d6;
  font-size: inherit;
  line-height: inherit;
}
form.custom-form .checkboxes-wrap [type="checkbox"]+span:not(.lever):before {
  border-radius: 4px;
  width: 20px;
  height: 20px;
  border: 2px solid #00e3d6;
  top: -5px;
  transform: none !important;
  left: 0 !important;
}
form.custom-form .checkboxes-wrap [type="checkbox"]:checked+span:not(.lever):before {
  background-color: #00e3d6;
}
form.custom-form .checkboxes-wrap [type="checkbox"]:checked+span:not(.lever):after {
  width: 10px;
  height: 6px;
  border: 3px solid #fff;
  border-top: none;
  border-right: none;
  transform: rotate(-45deg);
  left: 5px;
  top: 1px;
}
form.custom-form .submit-btn-wrap {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}
form.custom-form .submit-btn-wrap .btn-custom {
  width: 100%;
}
.enter-y {
  transform: translateY(50px) rotate(2deg);
  transition: all 1.2s cubic-bezier(0.37, 0.01, 0, 0.98);
  opacity: 0;
}
.enter {
  transition: all 1.2s cubic-bezier(0.37, 0.01, 0, 0.98);
  opacity: 0;
}
.seen .enter {
  opacity: 1;
  transition-delay: 0.3s !important;
}
.seen .enter-y {
  opacity: 1;
  transform: translate(0) scale(1) rotate(0);
}
.seen .delay-2 {
  transition-delay: 0.1s !important;
}
.seen .delay-3 {
  transition-delay: 0.15s !important;
}
.seen .delay-4 {
  transition-delay: 0.2s !important;
}
.seen .delay-5 {
  transition-delay: 0.25s !important;
}
.seen .delay-6 {
  transition-delay: 0.3s !important;
}
.seen .delay-7 {
  transition-delay: 0.35s !important;
}
.seen .delay-8 {
  transition-delay: 0.4s !important;
}
.seen .delay-9 {
  transition-delay: 0.45s !important;
}
.rotate-card {
  opacity: 0;
  transform: rotateY(90deg);
  transition: all 0.3s ease;
}
.seen .rotate-card {
  opacity: 1;
  transform: rotateY(0);
}
.seen .rotate-card:nth-child(1) {
  transition-delay: 0.3s !important;
}
.seen .rotate-card:nth-child(2) {
  transition-delay: 0.6s !important;
}
.seen .rotate-card:nth-child(3) {
  transition-delay: 0.9s !important;
}
.seen .rotate-card:nth-child(4) {
  transition-delay: 1.2s !important;
}
.seen .rotate-card:nth-child(5) {
  transition-delay: 1.5s !important;
}
.seen .rotate-card:nth-child(6) {
  transition-delay: 1.8s !important;
}
.seen .rotate-card:nth-child(7) {
  transition-delay: 2.1s !important;
}
.seen .rotate-card:nth-child(8) {
  transition-delay: 2.4s !important;
}
.seen .rotate-card:nth-child(9) {
  transition-delay: 2.7s !important;
}
.seen .rotate-card:nth-child(10) {
  transition-delay: 3s !important;
}
.seen .rotate-card:nth-child(11) {
  transition-delay: 3.3s !important;
}
.seen .rotate-card:nth-child(12) {
  transition-delay: 3.6s !important;
}
.dropdown-card {
  opacity: 0;
  transform: translateY(-100%);
  transition: all 0.3s ease;
}
.seen .dropdown-card {
  opacity: 1;
  transform: translateY(0);
}
.seen .dropdown-card:nth-child(1) {
  transition-delay: 0.3s !important;
}
.seen .dropdown-card:nth-child(2) {
  transition-delay: 0.6s !important;
}
.seen .dropdown-card:nth-child(3) {
  transition-delay: 0.9s !important;
}
.seen .dropdown-card:nth-child(4) {
  transition-delay: 1.2s !important;
}
.seen .dropdown-card:nth-child(5) {
  transition-delay: 1.5s !important;
}
.seen .dropdown-card:nth-child(6) {
  transition-delay: 1.8s !important;
}
.seen .dropdown-card:nth-child(7) {
  transition-delay: 2.1s !important;
}
.seen .dropdown-card:nth-child(8) {
  transition-delay: 2.4s !important;
}
.seen .dropdown-card:nth-child(9) {
  transition-delay: 2.7s !important;
}
.seen .dropdown-card:nth-child(10) {
  transition-delay: 3s !important;
}
.seen .dropdown-card:nth-child(11) {
  transition-delay: 3.3s !important;
}
.seen .dropdown-card:nth-child(12) {
  transition-delay: 3.6s !important;
}
.dropright-card {
  opacity: 0;
  transform: translateX(100%);
  transition: all 0.3s ease;
}
.seen .dropright-card {
  opacity: 1;
  transform: translateX(0);
}
.seen .dropright-card:nth-child(1) {
  transition-delay: 0.3s !important;
}
.seen .dropright-card:nth-child(2) {
  transition-delay: 0.6s !important;
}
.seen .dropright-card:nth-child(3) {
  transition-delay: 0.9s !important;
}
.seen .dropright-card:nth-child(4) {
  transition-delay: 1.2s !important;
}
.seen .dropright-card:nth-child(5) {
  transition-delay: 1.5s !important;
}
.seen .dropright-card:nth-child(6) {
  transition-delay: 1.8s !important;
}
.seen .dropright-card:nth-child(7) {
  transition-delay: 2.1s !important;
}
.seen .dropright-card:nth-child(8) {
  transition-delay: 2.4s !important;
}
.seen .dropright-card:nth-child(9) {
  transition-delay: 2.7s !important;
}
.seen .dropright-card:nth-child(10) {
  transition-delay: 3s !important;
}
.seen .dropright-card:nth-child(11) {
  transition-delay: 3.3s !important;
}
.seen .dropright-card:nth-child(12) {
  transition-delay: 3.6s !important;
}
.dropleft-card {
  opacity: 0;
  transform: translateX(-100%);
  transition: all 0.3s ease;
}
.seen .dropleft-card {
  opacity: 1;
  transform: translateX(0);
}
.seen .dropleft-card:nth-child(1) {
  transition-delay: 0.3s !important;
}
.seen .dropleft-card:nth-child(2) {
  transition-delay: 0.6s !important;
}
.seen .dropleft-card:nth-child(3) {
  transition-delay: 0.9s !important;
}
.seen .dropleft-card:nth-child(4) {
  transition-delay: 1.2s !important;
}
.seen .dropleft-card:nth-child(5) {
  transition-delay: 1.5s !important;
}
.seen .dropleft-card:nth-child(6) {
  transition-delay: 1.8s !important;
}
.seen .dropleft-card:nth-child(7) {
  transition-delay: 2.1s !important;
}
.seen .dropleft-card:nth-child(8) {
  transition-delay: 2.4s !important;
}
.seen .dropleft-card:nth-child(9) {
  transition-delay: 2.7s !important;
}
.seen .dropleft-card:nth-child(10) {
  transition-delay: 3s !important;
}
.seen .dropleft-card:nth-child(11) {
  transition-delay: 3.3s !important;
}
.seen .dropleft-card:nth-child(12) {
  transition-delay: 3.6s !important;
}
.error {
  color: #00e3d6;
  pointer-events: none;
}
.sidenav-overlay {
  backdrop-filter: blur(4px);
}
.top-menu {
  height: auto;
  line-height: inherit;
  background-color: rgba(21,21,21,0.7);
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  box-shadow: none;
  transition: all 0.3s ease;
  padding: 16px 0;
  width: 100vw;
}
@media only screen and (max-width: 600px) {
  .top-menu {
    padding: 14px 0;
  }
}
.top-menu.not-top {
  background: rgba(21,21,21,0.7);
  backdrop-filter: blur(20px);
  padding: 16px 0;
}
.top-menu .container {
  max-width: inherit;
}
.top-menu .nav-wrapper {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
}
@media only screen and (max-width: 600px) {
  .top-menu .nav-wrapper {
    justify-content: space-between;
  }
}
.top-menu .nav-wrapper__links {
  width: calc(100% - 100px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 1440px) {
  .top-menu .nav-wrapper__links {
    width: auto;
  }
}
.top-menu .brand-logo {
  position: static;
  width: 168px;
  transform: none;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 600px) {
  .top-menu .brand-logo {
    width: 168px;
    margin-left: 54px;
  }
}
.top-menu .brand-logo img {
  max-width: 100%;
}
.top-menu .anqors-wrap {
  margin-right: 4px;
}
.top-menu .top-menu-link {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  transition: color 0.3s ease;
  position: relative;
  padding-left: 24px;
  padding-right: 24px;
}
.top-menu .top-menu-link:hover {
  color: #00e3d6;
}
.top-menu .top-menu-link.active {
  color: #00e3d6;
}
.top-menu .top-menu-link.active:after {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  bottom: -10px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  transform: translateX(-50%);
  background-color: #00e3d6;
}
.top-menu__btns-wrap {
  display: flex;
  align-items: center;
}
.top-menu__btns-wrap .btn-custom {
  margin-left: 20px;
  font-size: 16px;
  line-height: 20px;
}
@media only screen and (max-width: 600px) {
  .top-menu__btns-wrap .btn-custom.btn-custom--border {
    display: none;
  }
}
.top-menu__hamburger {
  line-height: inherit !important;
  display: block !important;
  margin-left: 0 !important;
  margin-right: 46px !important;
  width: 30px !important;
  height: 30px !important;
}
.top-menu__hamburger img {
  max-width: 100%;
}
@media only screen and (max-width: 960px) {
  .top-menu__hamburger {
    margin-right: 32px !important;
  }
}
@media only screen and (max-width: 600px) {
  .top-menu__hamburger {
    position: absolute !important;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media only screen and (max-width: 1440px) {
  .desctop-menu {
    display: none;
  }
}
.mobile-menu {
  display: none;
}
.mobile-menu__left-part {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1440px) {
  .mobile-menu {
    display: block;
  }
}
.sidenav {
  background-color: #151515;
  width: 400px;
}
@media only screen and (max-width: 720px) {
  .sidenav {
    width: 100%;
  }
}
.sidenav .top-menu__btns-wrap {
  margin-top: 24px;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
}
.sidenav .top-menu__btns-wrap .btn-custom {
  display: flex !important;
  margin: 0 auto 16px !important;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
}
.sidenav .sidenav-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #151515;
  padding: 24px;
}
.sidenav .sidenav-header .sidenav-cross {
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgba(255,255,255,0.06);
  padding: 0;
}
.sidenav .sidenav-header .sidenav-cross svg {
  width: 10px;
  height: 10px;
}
.sidenav .sidenav-header .sidenav-cross svg rect {
  fill: #fff;
}
.sidenav .sidenav-header .sidenav-cross:hover svg rect {
  fill: #fc311d;
}
.sidenav .sidenav-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.sidenav .sidenav-content .anqors-wrap {
  width: 100%;
}
.sidenav .sidenav-content li {
  line-height: inherit;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.sidenav .sidenav-content .top-menu-link {
  height: auto;
  margin: 15px 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  transition: color 0.3s ease;
}
.sidenav .sidenav-content .top-menu-link:hover {
  color: #00e3d6;
}
.mobile-logo {
  display: none;
}
.header {
  padding-top: 148px;
  padding-bottom: 210px;
  position: relative;
  margin-bottom: 0 !important;
}
@media only screen and (max-width: 1248px) {
  .header {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}
@media only screen and (max-width: 720px) {
  .header {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media only screen and (max-width: 600px) {
  .header {
    padding-top: 360px;
    padding-bottom: 40px;
  }
}
.header__content {
  display: flex;
  align-items: center;
  z-index: 2;
}
@media only screen and (max-width: 600px) {
  .header__content {
    flex-wrap: wrap;
  }
}
.header__img-bg {
  position: absolute;
  height: 100%;
  width: auto;
  top: 0;
  right: 0;
}
@media only screen and (max-width: 720px) {
  .header__img-bg {
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }
}
.header__block--text {
  width: 54%;
  position: relative;
  z-index: 2;
  order: 1;
}
@media only screen and (max-width: 600px) {
  .header__block--text {
    width: 100%;
    order: 2;
  }
}
.header__block--img {
  width: 46%;
  order: 2;
}
@media only screen and (max-width: 600px) {
  .header__block--img {
    width: 100%;
    order: 1;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.header__block--img img {
  position: absolute;
  left: auto;
  right: -200px;
  top: 50%;
  transform: translateY(-50%);
  height: 1180px;
  width: auto !important;
}
@media only screen and (max-width: 1440px) {
  .header__block--img img {
    height: 1180px;
  }
}
@media only screen and (max-width: 960px) {
  .header__block--img img {
    height: 950px;
  }
}
@media only screen and (max-width: 720px) {
  .header__block--img img {
    height: 764px;
  }
}
@media only screen and (max-width: 600px) {
  .header__block--img img {
    height: 452px;
    left: 50%;
    transform: translate(-50%, 0);
    top: 0;
    right: auto;
  }
}
.header__description {
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  max-width: 604px;
}
.header__btns-wrap {
  display: flex;
  margin-top: 48px;
}
@media only screen and (max-width: 720px) {
  .header__btns-wrap {
    margin-top: 32px;
  }
}
@media only screen and (max-width: 600px) {
  .header__btns-wrap {
    justify-content: center;
  }
}
.header__btns-wrap .btn-custom {
  min-width: 228px;
}
.header--commisions {
  background-color: #151515;
  padding-bottom: 100px;
}
@media only screen and (max-width: 600px) {
  .header--commisions {
    padding-bottom: 20px;
    padding-top: 40px;
  }
}
.header--commisions:after {
  content: '';
  display: block;
  height: 160px;
  width: calc(100% + 80px);
  position: absolute;
  left: 50%;
  bottom: -80px;
  background-color: #151515;
  transform: translateX(-50%) rotate(-4.5deg);
  z-index: 0;
  overflow: hidden;
}
.header--commisions .header__title {
  text-transform: none;
  font-size: 48px;
  line-height: 56px;
}
@media only screen and (max-width: 600px) {
  .header--commisions .header__title {
    font-size: 36px;
    line-height: 48px;
  }
}
.header--commisions .header__btns-wrap {
  margin-top: 40px;
}
.header--commisions .header__terms {
  margin-top: 24px;
}
.header--commisions .header__terms a {
  color: rgba(255,255,255,0.45);
  font-weight: 600;
  transition: color 0.3s ease;
}
.header--commisions .header__terms a:hover {
  color: #00e3d6;
}
.header--commisions .header__description {
  color: rgba(255,255,255,0.45);
}
.header .header__block--text {
  order: 1;
}
.header .header__block--img {
  order: 2;
}
@media only screen and (max-width: 600px) {
  .header .header__block--img {
    margin-top: 60px;
  }
}
@media only screen and (max-width: 960px) {
  .header .header__block--img img {
    max-width: inherit;
    width: 174%;
  }
}
@media only screen and (max-width: 720px) {
  .header .header__block--img img {
    width: 200%;
  }
}
@media only screen and (max-width: 600px) {
  .header .header__block--img img {
    width: 520px;
  }
}
.header .header__slider {
  position: relative;
  padding-bottom: 180px;
}
@media only screen and (max-width: 600px) {
  .header .header__slider {
    padding-bottom: 0;
  }
}
@media only screen and (max-width: 600px) {
  .header .header__slider .header__block--img img {
    top: -370px;
  }
}
.header .header__slider .header__content {
  display: flex !important;
  position: relative;
}
.header .header__slider .slick-list {
  overflow: visible;
}
.header .header__slider .slick-slide {
  opacity: 0;
  transition: opacity 0.3s ease;
}
.header .header__slider .slick-slide.slick-current {
  opacity: 1;
}
.header .header__slider .slick-arrow {
  position: absolute;
  z-index: 3;
  bottom: 0;
  cursor: pointer;
  opacity: 0.5;
  transition: all 0.3s ease;
}
.header .header__slider .slick-arrow:hover {
  opacity: 1;
}
.header .header__slider .prev-btn {
  left: 0;
}
.header .header__slider .next-btn {
  left: 92px;
}
.header .header__slider .slick-dots {
  position: absolute;
  width: 100%;
  z-index: 3;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header .header__slider .slick-dots li {
  width: 56px;
  height: 2px;
  background: rgba(255,255,255,0.3);
  margin: 0 6px;
  cursor: pointer;
  position: relative;
}
.header .header__slider .slick-dots li.slick-active {
  background: #00e3d6;
}
.header .header__slider .slick-dots li button {
  line-height: 0;
  font-size: 0;
  color: transparent;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.header-v2 {
  padding-top: 64px;
  padding-bottom: 0;
  margin-bottom: 200px;
  position: relative;
  background-color: #151515;
}
@media only screen and (max-width: 720px) {
  .header-v2 {
    padding-bottom: 160px;
    margin-bottom: 300px;
  }
}
@media only screen and (max-width: 600px) {
  .header-v2 {
    padding-bottom: 100px;
  }
}
.header-v2:after {
  content: '';
  display: block;
  height: 160px;
  width: calc(100% + 80px);
  position: absolute;
  left: 50%;
  bottom: -80px;
  background-color: #151515;
  transform: translateX(-50%) rotate(-4.5deg);
  z-index: 0;
  overflow: hidden;
}
.header-v2:before {
  content: '';
  display: block;
  width: 326px;
  height: 326px;
  position: absolute;
  bottom: -320px;
  left: -200px;
  border-radius: 50%;
  border: 1px solid #00e3d6;
  z-index: 1;
}
@media only screen and (max-width: 1440px) {
  .header-v2:before {
    left: -100px;
    bottom: -260px;
    width: 230px;
    height: 230px;
  }
}
@media only screen and (max-width: 720px) {
  .header-v2:before {
    display: none;
  }
}
.header-v2__content {
  position: relative;
  display: flex;
  align-items: flex-start;
  z-index: 2;
}
@media only screen and (max-width: 720px) {
  .header-v2__content {
    flex-wrap: wrap;
  }
}
.header-v2__block--text {
  width: 560px;
  position: relative;
  z-index: 2;
}
@media only screen and (max-width: 1440px) {
  .header-v2__block--text {
    width: 420px;
  }
}
@media only screen and (max-width: 720px) {
  .header-v2__block--text {
    width: 100%;
  }
}
.header-v2__block--img {
  width: calc(100% - 560px);
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin-top: 60px;
}
@media only screen and (max-width: 1440px) {
  .header-v2__block--img {
    width: calc(100% - 420px);
    margin-top: 160px;
  }
}
@media only screen and (max-width: 960px) {
  .header-v2__block--img {
    margin-top: 60px;
  }
}
@media only screen and (max-width: 720px) {
  .header-v2__block--img {
    width: 100%;
  }
}
.header-v2__block--img img {
  position: absolute;
  top: 0;
  right: -32%;
  width: 164%;
}
@media only screen and (max-width: 960px) {
  .header-v2__block--img img {
    width: 300%;
    right: -132%;
  }
}
@media only screen and (max-width: 720px) {
  .header-v2__block--img img {
    width: 160%;
    right: -25%;
  }
}
@media only screen and (max-width: 600px) {
  .header-v2__block--img img {
    width: 1000px;
    right: auto;
    left: -248px;
  }
}
.header-v2__back-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 40px;
}
.header-v2__back-btn:hover .header-v2__back-btn-text {
  color: #00e3d6 !important;
}
.header-v2__back-btn:hover .header-v2__back-btn-img path {
  fill: #00e3d6 !important;
}
.header-v2__back-btn-img {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}
.header-v2__back-btn-img path {
  transition: fill 0.3s ease;
}
.header-v2__back-btn-text {
  font-weight: bold;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: #808191;
  transition: color 0.3s ease;
}
.header-v2__logo-wrap {
  max-width: 200px;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
.header-v2__logo-wrap img {
  max-width: 100%;
  max-height: 52px;
}
.header-v2__description {
  font-size: 16px;
  line-height: 24px;
}
.header-v2__license-wrap {
  margin-top: 24px;
}
.header-v2__license-img {
  max-width: 180px;
  max-height: 50px;
}
.header-v2__btns-wrap {
  display: flex;
  align-items: center;
  margin-top: 24px;
  flex-wrap: wrap;
  width: calc(100% + 32px);
  margin-left: -16px;
}
.header-v2__btns-wrap .btn-custom {
  margin: 16px;
}
.header-v2__goto {
  display: flex;
  align-items: center;
  margin: 16px;
}
.header-v2__goto:hover .header-v2__goto-text {
  color: #00e3d6;
}
.header-v2__goto-text {
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  transition: all 0.3s ease;
  color: #737373;
}
.header-v2__goto-img {
  width: 24px;
  height: 24px;
  margin-left: 12px;
}
.s-achievements {
  margin-top: 0;
  margin-bottom: 0;
}
.s-achievements__title {
  text-align: left;
}
.s-achievements__text-block {
  margin-top: 130px;
  margin-bottom: 140px;
  max-width: 658px;
  padding-right: 24px;
}
@media only screen and (max-width: 600px) {
  .s-achievements__text-block {
    margin-bottom: 0;
    margin-top: 80px;
  }
}
.s-achievements__text-block-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.5px;
  color: #151515;
  margin-bottom: 24px;
}
.s-achievements__text-block-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
  color: #151515;
}
.s-achievements__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
@media only screen and (max-width: 600px) {
  .s-achievements__content {
    flex-wrap: wrap;
  }
}
.s-achievements__overlay {
  position: absolute;
  top: 20px;
  left: -200px;
  transform: rotate(-2.5deg);
  width: 1940px;
  height: 84%;
  background-color: #00e3d6;
  overflow: hidden;
}
@media only screen and (max-width: 720px) {
  .s-achievements__overlay {
    top: 0;
  }
}
@media only screen and (max-width: 600px) {
  .s-achievements__overlay {
    height: 100%;
  }
}
.s-achievements__overlay:after {
  content: '';
  position: absolute;
  left: -178px;
  top: -251px;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  border: 1px solid rgba(255,255,255,0.4);
}
.s-achievements__img--tablet {
  display: none;
}
@media only screen and (max-width: 960px) {
  .s-achievements__img--tablet {
    display: block;
  }
}
@media only screen and (max-width: 960px) {
  .s-achievements__img--desck {
    display: none;
  }
}
.s-achievements__block {
  position: relative;
  z-index: 2;
}
.s-achievements__block--img {
  width: 50%;
}
.s-achievements__block--img img {
  max-width: 100%;
}
@media only screen and (max-width: 960px) {
  .s-achievements__block--img {
    padding-right: 30px;
  }
}
@media only screen and (max-width: 600px) {
  .s-achievements__block--img {
    padding-right: 0;
    padding-left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }
}
.s-achievements__block--text {
  width: calc(50% + 40px);
  margin-left: -20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
@media only screen and (max-width: 600px) {
  .s-achievements__block--text {
    width: calc(100% + 40px);
  }
}
.s-achievements__mini-block {
  width: calc(50% - 40px);
  margin: 0 20px 60px;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 960px) {
  .s-achievements__mini-block {
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 720px) {
  .s-achievements__mini-block {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 600px) {
  .s-achievements__mini-block {
    justify-content: center;
  }
}
.s-achievements__mini-block-content {
  display: flex;
  align-items: flex-start;
}
@media only screen and (max-width: 600px) {
  .s-achievements__mini-block-content {
    flex-wrap: wrap;
  }
}
.s-achievements__mini-img-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  margin-right: 24px;
}
@media only screen and (max-width: 960px) {
  .s-achievements__mini-img-wrap {
    width: 55px;
    height: 55px;
    margin-right: 16px;
  }
}
@media only screen and (max-width: 600px) {
  .s-achievements__mini-img-wrap {
    width: 100%;
    height: 45px;
    margin-bottom: 12px;
  }
}
.s-achievements__mini-img-wrap img {
  max-width: 100%;
  max-height: 100%;
}
.s-achievements__mini-content-wrap {
  width: calc(100% - 104px);
}
@media only screen and (max-width: 960px) {
  .s-achievements__mini-content-wrap {
    width: calc(100% - 71px);
  }
}
@media only screen and (max-width: 600px) {
  .s-achievements__mini-content-wrap {
    width: 100%;
  }
}
.s-achievements__mini-block-title {
  font-family: 'Helvetica Neue';
  color: #151515;
  font-weight: 700;
  font-size: 56px;
  line-height: 72px;
  letter-spacing: -3px;
  margin-bottom: 2px;
}
@media only screen and (max-width: 1440px) {
  .s-achievements__mini-block-title {
    font-size: 48px;
    line-height: 50px;
  }
}
@media only screen and (max-width: 960px) {
  .s-achievements__mini-block-title {
    font-size: 36px;
    line-height: 48px;
  }
}
@media only screen and (max-width: 720px) {
  .s-achievements__mini-block-title {
    font-size: 30px;
    line-height: 40px;
  }
}
@media only screen and (max-width: 600px) {
  .s-achievements__mini-block-title {
    text-align: center;
  }
}
.s-achievements__mini-block-text {
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  color: #151515;
}
@media only screen and (max-width: 600px) {
  .s-achievements__mini-block-text {
    text-align: center;
  }
}
.s-brands {
  overflow: hidden;
  position: relative;
}
@media only screen and (max-width: 960px) {
  .s-brands {
    margin-top: 0;
  }
}
.s-brands__overlay {
  position: absolute;
  z-index: 1;
}
.s-brands__overlay img {
  max-width: 100%;
}
.s-brands__overlay--right {
  top: 0;
  right: -131px;
  width: 516px;
  height: 516px;
}
@media only screen and (max-width: 1440px) {
  .s-brands__overlay--right {
    right: -190px;
  }
}
@media only screen and (max-width: 1248px) {
  .s-brands__overlay--right {
    right: -326px;
  }
}
@media only screen and (max-width: 960px) {
  .s-brands__overlay--right {
    right: -296px;
  }
}
@media only screen and (max-width: 720px) {
  .s-brands__overlay--right {
    right: -436px;
  }
}
@media only screen and (max-width: 600px) {
  .s-brands__overlay--right {
    right: -266px;
    width: 334px;
    height: 334px;
  }
}
.s-brands__overlay--left {
  top: 702px;
  left: -64px;
  width: 310px;
  height: 310px;
}
@media only screen and (max-width: 1440px) {
  .s-brands__overlay--left {
    left: -150px;
  }
}
@media only screen and (max-width: 1248px) {
  .s-brands__overlay--left {
    left: -246px;
  }
}
@media only screen and (max-width: 960px) {
  .s-brands__overlay--left {
    left: -176px;
  }
}
@media only screen and (max-width: 720px) {
  .s-brands__overlay--left {
    left: -298px;
  }
}
@media only screen and (max-width: 600px) {
  .s-brands__overlay--left {
    left: -108px;
    width: 186px;
    height: 186px;
  }
}
.s-brands__overlay--left img {
  transform: rotate(90deg);
}
.s-brands__content {
  position: relative;
  z-index: 2;
}
.s-brands__text {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
@media only screen and (max-width: 1248px) {
  .s-brands__text {
    font-size: 14px;
    line-height: 22px;
  }
}
.s-brands__tabs-wrap {
  justify-content: center;
  align-items: center;
  display: none;
}
.s-brands__tabs {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 72px auto 60px;
  height: auto;
  padding-bottom: 12px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  width: auto;
}
.s-brands__tabs::-webkit-scrollbar {
  display: none;
}
.s-brands__tabs .indicator {
  background-color: transparent;
  height: 8px;
}
.s-brands__tabs .indicator:after {
  position: absolute;
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50px;
  background-color: #00e3d6;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.s-brands__tabs .tab {
  height: auto;
  line-height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}
.s-brands__tabs .tab a img {
  filter: grayscale(100%);
  opacity: 0.7;
  transition: all 0.3s ease;
  max-width: 140px;
  max-height: 32px;
}
.s-brands__tabs .tab a.active {
  background-color: transparent !important;
}
.s-brands__tabs .tab a.active img {
  filter: none;
  opacity: 1;
}
.s-brands__tab-content-wrap {
  display: flex;
  padding: 0 0 40px;
  align-items: center;
}
@media only screen and (max-width: 960px) {
  .s-brands__tab-content-wrap {
    flex-wrap: wrap;
    align-items: flex-start;
    padding-top: 40px;
  }
}
.s-brands__tab-content-text-wrap {
  width: 43%;
  padding-right: 160px;
  padding-top: 72px;
}
@media only screen and (max-width: 1440px) {
  .s-brands__tab-content-text-wrap {
    padding-right: 80px;
    padding-top: 0;
  }
}
@media only screen and (max-width: 1248px) {
  .s-brands__tab-content-text-wrap {
    padding-right: 52px;
  }
}
@media only screen and (max-width: 960px) {
  .s-brands__tab-content-text-wrap {
    width: 100%;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    order: 1;
  }
}
.s-brands__tab-content-img-wrap {
  width: 57%;
  position: relative;
  padding-top: 80px;
  display: flex;
  justify-content: center;
}
.s-brands__tab-content-img-wrap img {
  height: 580px;
}
@media only screen and (max-width: 1440px) {
  .s-brands__tab-content-img-wrap img {
    height: 500px;
  }
}
@media only screen and (max-width: 1248px) {
  .s-brands__tab-content-img-wrap img {
    height: 430px;
  }
}
@media only screen and (max-width: 960px) {
  .s-brands__tab-content-img-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    order: 2;
    padding-top: 32px;
    margin-bottom: 32px;
  }
  .s-brands__tab-content-img-wrap img {
    left: auto;
    max-width: 100%;
    height: auto;
  }
}
.s-brands__tab-img {
  position: relative;
  z-index: 2;
  pointer-events: none;
}
.s-brands__logo-wrap {
  display: flex;
  margin-bottom: 20px;
}
@media only screen and (max-width: 960px) {
  .s-brands__logo-wrap {
    justify-content: center;
  }
}
.s-brands__logo-wrap img {
  max-width: 219px;
  max-height: 123px;
}
@media only screen and (max-width: 960px) {
  .s-brands__logo-wrap img {
    max-width: 178px;
    max-height: 100px;
  }
}
@media only screen and (max-width: 600px) {
  .s-brands__logo-wrap img {
    max-width: 142px;
    max-height: 80px;
  }
}
.s-brands__info {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-top: 32px;
  color: #6c5dd3;
}
.s-brands__license-wrap {
  margin-top: 28px;
  display: flex;
}
.s-brands__license-wrap img {
  max-width: 140px;
  max-height: 62px;
}
.s-brands__btns-wrap {
  display: flex;
  align-items: center;
  margin-top: 40px;
  width: calc(100% + 10px);
  margin-left: -10px;
  flex-wrap: wrap;
}
@media only screen and (max-width: 600px) {
  .s-brands__btns-wrap {
    justify-content: center;
  }
}
.s-brands__btns {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .s-brands__btns {
    width: 100%;
  }
}
.s-brands__btns .btn-custom {
  margin: 10px;
}
@media only screen and (max-width: 1248px) {
  .s-brands__btns .btn-custom {
    padding: 20px 32px;
  }
}
@media only screen and (max-width: 600px) {
  .s-brands__btns .btn-custom {
    width: 100%;
  }
}
.s-brands__goto {
  display: flex;
  align-items: center;
  margin: 10px 10px 10px 40px;
}
@media only screen and (max-width: 600px) {
  .s-brands__goto {
    margin-top: 32px;
    margin-left: 10px;
  }
}
.s-brands__goto:hover .s-brands__goto-text {
  color: #00e3d6;
}
.s-brands__goto-text {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  transition: all 0.3s ease;
  color: #fff;
}
.s-brands__goto-img {
  width: 24px;
  height: 24px;
  margin-left: 12px;
}
.s-commissions {
  position: relative;
}
@media only screen and (min-width: 1920px) {
  .s-commissions {
    margin-top: 240px;
  }
}
.s-commissions__title {
  color: #151515;
}
.s-commissions:before {
  content: '';
  display: block;
  position: absolute;
  top: 200px;
  width: 180px;
  height: 200px;
  left: -70px;
  background: url("/img/s-commissions-icon-coins.webp") center no-repeat;
  background-size: cover !important;
  z-index: 2;
}
@media only screen and (min-width: 720px) {
  .s-commissions:before {
    top: -90px;
    width: 220px;
    height: 289px;
    left: -80px;
  }
}
@media only screen and (min-width: 960px) {
  .s-commissions:before {
    top: -60px;
    width: 263px;
    height: 350px;
    left: 0;
  }
}
@media only screen and (min-width: 1248px) {
  .s-commissions:before {
    width: 399px;
    height: 480px;
    top: -130px;
  }
}
@media only screen and (min-width: 1920px) {
  .s-commissions:before {
    width: 460px;
    height: 602px;
    top: -200px;
  }
}
.s-commissions:after {
  content: '';
  display: block;
  position: absolute;
  width: 129px;
  height: 161px;
  top: 182px;
  right: -55px;
  background: url("/img/s-commissions-icon-right.webp") center no-repeat;
  background-size: cover !important;
  z-index: 2;
}
@media only screen and (min-width: 720px) {
  .s-commissions:after {
    width: 208px;
    height: 260px;
    top: 55px;
    right: -86px;
  }
}
@media only screen and (min-width: 960px) {
  .s-commissions:after {
    width: 289px;
    height: 360px;
    top: 55px;
    right: -50px;
  }
}
@media only screen and (min-width: 1248px) {
  .s-commissions:after {
    width: 289px;
    height: 360px;
    top: 55px;
    right: 0;
  }
}
@media only screen and (min-width: 1920px) {
  .s-commissions:after {
    width: 393px;
    height: 490px;
    top: 0;
    right: 0;
  }
}
.s-commissions__overaly {
  content: '';
  display: block;
  height: 480px;
  width: 2000px;
  position: absolute;
  left: 50%;
  top: -60px;
  background-color: #f4f4f4;
  transform: translateX(-50%) rotate(-2.5deg);
  z-index: 1;
}
@media only screen and (min-width: 1248px) {
  .s-commissions__overaly {
    top: -120px;
    height: 540px;
  }
}
@media only screen and (min-width: 1920px) {
  .s-commissions__overaly {
    height: 741px;
  }
}
.s-commissions__content {
  position: relative;
}
.s-commissions__content-wrap {
  position: relative;
  z-index: 3;
}
.s-commissions__pretitle {
  text-align: center;
  color: #909090;
}
.s-commissions__pretitle a {
  color: #00e3d6;
  font-weight: 700;
  transition: color 0.3s ease;
}
.s-commissions__pretitle a:hover {
  color: #00e3d6;
}
.s-commissions__blocks-wrap {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 640px;
}
@media only screen and (min-width: 1248px) {
  .s-commissions__blocks-wrap {
    width: calc(100% + 24px);
    max-width: inherit;
    margin-left: -12px;
    margin-right: inherit;
    margin-top: 52px;
  }
}
@media only screen and (min-width: 1920px) {
  .s-commissions__blocks-wrap {
    margin-top: 86px;
  }
}
.s-commissions__block {
  margin: 12px 0;
  width: calc(100% / 1 - 24px);
  background-color: #fff;
  padding: 48px;
}
.s-commissions__block:first-child {
  border-top-left-radius: 60px;
}
.s-commissions__block:last-child {
  border-bottom-right-radius: 60px;
}
@media only screen and (min-width: 600px) {
  .s-commissions__block {
    margin: 12px;
    padding: 48px 38px;
    width: calc(100% / 2 - 24px);
  }
}
@media only screen and (min-width: 1248px) {
  .s-commissions__block {
    width: calc(100% / 4 - 24px);
  }
}
@media only screen and (min-width: 1920px) {
  .s-commissions__block {
    padding: 68px 48px;
    max-width: inherit;
  }
}
.s-commissions__block-title {
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -1px;
  color: #283342;
  font-family: 'Helvetica Neue';
  font-weight: 700;
  margin-bottom: 12px;
  width: 100%;
  text-transform: uppercase;
}
@media only screen and (min-width: 1920px) {
  .s-commissions__block-title {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 16px;
  }
}
.s-commissions__block-price {
  font-size: 22px;
  line-height: 27px;
  letter-spacing: -0.5px;
  color: #cb6ce4;
  font-family: inherit;
  font-weight: 700;
  height: 60px;
  margin-bottom: 32px;
  width: 100%;
}
@media only screen and (min-width: 1670px) {
  .s-commissions__block-price {
    height: 80px;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 48px;
  }
}
.s-commissions__block-list {
  width: 100%;
}
.s-commissions__block-list li {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #151515;
  position: relative;
  margin-bottom: 12px;
  padding-left: 24px;
}
.s-commissions__block-list li:before {
  content: '';
  display: block;
  position: absolute;
  height: 8px;
  width: 8px;
  background-color: #151515;
  border-radius: 50%;
  left: 0;
  top: 7px;
}
@media only screen and (min-width: 1920px) {
  .s-commissions__block-list li:before {
    top: 11px;
  }
}
@media only screen and (min-width: 1920px) {
  .s-commissions__block-list li {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
  }
}
.s-commissions__btns-wrap {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.s-commissions__btns-wrap .btn-custom {
  min-width: 220px;
}
.s-commissions__logos-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
@media only screen and (max-width: 960px) {
  .s-commissions__logos-wrap {
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
}
.s-commissions__logo {
  margin: 35px;
  opacity: 1;
}
@media only screen and (max-width: 960px) {
  .s-commissions__logo {
    margin: 12px;
  }
}
.s-commissions__logos-text {
  text-align: center;
}
.header--commisions + .s-commissions {
  padding-top: 0;
  margin-top: -200px;
}
.header--commisions + .s-commissions .s-commissions__logos-text {
  color: RGBA(21, 21, 21, 0.45);
  font-weight: 500;
}
.header--commisions + .s-commissions .s-commissions__blocks-wrap {
  margin-top: 0;
}
.modal__thanks-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #151515;
  margin-bottom: 12px;
}
.modal__thanks-text {
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #8f95a5;
}
.modal__thanks-img {
  margin-top: -86px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  max-width: 100%;
}
@media only screen and (max-width: 500px) {
  .modal__thanks-img {
    margin-top: -76px !important;
  }
}
.modal-thanks {
  padding-left: 52px;
  padding-right: 52px;
  overflow: visible;
}
.modal-thanks .submit-btn-wrap {
  margin-top: 42px;
}
.s-why {
  position: relative;
  overflow: visible;
  padding-top: 40px;
  z-index: 4;
}
@media only screen and (max-width: 600px) {
  .s-why {
    z-index: 6;
  }
}
.s-why__bg-img {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.s-why__content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.s-why__block--text {
  width: 50%;
}
@media only screen and (max-width: 960px) {
  .s-why__block--text {
    width: 100%;
    order: 2;
  }
}
@media only screen and (max-width: 720px) {
  .s-why__block--text {
    max-width: 100%;
  }
}
.s-why__block--img {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}
@media only screen and (max-width: 960px) {
  .s-why__block--img {
    order: 1;
    width: 100%;
    justify-content: center;
  }
}
.s-why__block--logos {
  display: flex;
  align-items: center;
  border-top: 1px solid #3a3b40;
  padding-top: 64px;
  margin-top: 64px;
  order: 2;
}
@media only screen and (max-width: 720px) {
  .s-why__block--logos {
    padding-top: 48px;
    margin-top: 48px;
    flex-wrap: wrap;
  }
}
.s-why__img {
  margin-right: -80px;
  max-width: 110%;
  width: 110%;
  pointer-events: none;
}
@media only screen and (max-width: 960px) {
  .s-why__img {
    max-width: 100%;
    position: absolute;
    top: 200px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0.2;
    width: 100%;
    margin-right: 0;
  }
}
@media only screen and (max-width: 600px) {
  .s-why__img {
    max-width: inherit;
    width: 600px;
  }
}
.s-why__title {
  text-align: left;
}
@media only screen and (max-width: 960px) {
  .s-why__title {
    text-align: center;
  }
}
.s-why__list-text {
  font-size: 16px;
  line-height: 24px;
  color: #7f7f7f;
}
@media only screen and (max-width: 1440px) {
  .s-why__list-text {
    font-size: 14px;
    line-height: 20px;
  }
}
.s-why__list-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: calc(100% + 48px);
  margin-left: -24px;
  margin-top: 40px;
}
.s-why__list {
  margin: 20px 24px;
  width: calc(48% - 48px);
}
@media only screen and (max-width: 1440px) {
  .s-why__list {
    width: calc(50% - 48px);
  }
}
@media only screen and (max-width: 1248px) {
  .s-why__list {
    width: calc(100% / 2 - 48px);
  }
}
@media only screen and (max-width: 600px) {
  .s-why__list {
    width: calc(100% / 1 - 48px);
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
.s-why__list-title-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.s-why__list-icon-wrap {
  width: 64px;
  height: 64px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.s-why__list-icon-wrap img {
  max-width: 100%;
}
.s-why__list-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  margin-top: 8px;
  max-width: 200px;
}
.s-why__btns-wrap {
  display: flex;
  margin-top: 40px;
}
@media only screen and (max-width: 1440px) {
  .s-why__btns-wrap {
    margin-top: 24px;
  }
}
@media only screen and (max-width: 960px) {
  .s-why__btns-wrap {
    justify-content: center;
  }
}
.s-why__btns-wrap .btn-custom {
  padding-left: 40px;
  padding-right: 40px;
}
.s-why__logos-title {
  font-family: 'Helvetica Neue';
  font-size: 36px;
  line-height: 40px;
  color: #fff;
  font-weight: 700;
}
@media only screen and (max-width: 600px) {
  .s-why__logos-title {
    font-size: 24px;
    line-height: 32px;
  }
}
.s-why__logos-wrap {
  display: flex;
  align-items: center;
  margin-left: 60px;
}
@media only screen and (max-width: 1440px) {
  .s-why__logos-wrap {
    margin-left: 32px;
  }
}
@media only screen and (max-width: 720px) {
  .s-why__logos-wrap {
    width: 100%;
    flex-wrap: wrap;
    margin-left: 0;
    margin-top: 8px;
  }
}
.s-why__logo {
  margin: 0 20px;
  max-width: 140px;
  max-height: 50px;
}
.s-why__logo:first-child {
  margin-left: 0;
}
.s-why__logo:last-child {
  margin-right: 0;
}
@media only screen and (max-width: 600px) {
  .s-why__logo {
    margin: 10px;
  }
}
.s-news {
  position: relative;
}
@media only screen and (max-width: 960px) {
  .s-news {
    margin-top: 120px;
  }
}
.s-news__overlay {
  content: '';
  display: block;
  height: 640px;
  width: calc(100% + 80px);
  position: absolute;
  left: 50%;
  top: -20px;
  background-color: #00e3d6;
  transform: translateX(-50%) rotate(-2.5deg);
  z-index: 1;
  overflow: hidden;
}
@media only screen and (max-width: 1440px) {
  .s-news__overlay {
    height: 580px;
  }
}
@media only screen and (max-width: 700px) {
  .s-news__overlay {
    height: 85%;
  }
}
.s-news__overlay:before {
  content: '';
  display: block;
  width: 390px;
  height: 390px;
  position: absolute;
  border-radius: 50%;
  border: 1px solid rgba(255,255,255,0.4);
  top: -80px;
  left: -60px;
}
@media only screen and (max-width: 1440px) {
  .s-news__overlay:before {
    width: 250px;
    height: 250px;
  }
}
@media only screen and (max-width: 700px) {
  .s-news__overlay:before {
    display: none;
  }
}
.s-news__overlay:after {
  content: '';
  display: block;
  width: 524px;
  height: 524px;
  position: absolute;
  border-radius: 50%;
  border: 1px solid rgba(255,255,255,0.4);
  top: -180px;
  right: -110px;
}
@media only screen and (max-width: 1440px) {
  .s-news__overlay:after {
    height: 480px;
    width: 480px;
    right: -200px;
  }
}
.s-news__content-wrap {
  position: relative;
  z-index: 2;
}
.s-news__title {
  text-align: left;
  color: #151515;
}
.s-news__content {
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (max-width: 700px) {
  .s-news__content {
    position: relative;
  }
}
.s-news__block--img {
  width: 500px;
}
@media only screen and (max-width: 960px) {
  .s-news__block--img {
    width: 45%;
    position: relative;
  }
}
@media only screen and (max-width: 700px) {
  .s-news__block--img {
    position: static;
    width: 100%;
  }
}
.s-news__block--img img {
  margin-left: -180px;
  margin-top: 40px;
}
@media only screen and (max-width: 1440px) {
  .s-news__block--img img {
    max-width: 100%;
    margin-left: -60px;
    margin-top: 70px;
  }
}
@media only screen and (max-width: 960px) {
  .s-news__block--img img {
    max-width: inherit;
    height: 360px;
    bottom: 80px;
    right: 60px;
    position: absolute;
  }
}
@media only screen and (max-width: 700px) {
  .s-news__block--img img {
    left: -40px;
    height: 300px;
    bottom: 80px;
  }
}
.s-news__block--slider {
  width: calc(100% - 500px);
  position: relative;
  padding-top: 120px;
}
@media only screen and (max-width: 960px) {
  .s-news__block--slider {
    width: 55%;
  }
}
@media only screen and (max-width: 700px) {
  .s-news__block--slider {
    width: 100%;
    padding-top: 0;
  }
}
.s-news__btns-wrap {
  position: absolute;
  top: 16px;
  display: flex;
  justify-content: flex-end;
  padding-right: 220px;
  width: 100%;
}
@media only screen and (max-width: 700px) {
  .s-news__btns-wrap {
    position: static;
    justify-content: center;
    padding-right: 0;
    margin-top: 220px;
  }
}
@media only screen and (min-width: 700px) {
  .s-news__btns-wrap .btn-custom--border {
    border: 1px solid #fff !important;
    background-color: #00e3d6;
  }
}
.s-news__btns-wrap .btn-custom--border:hover {
  background-color: #fff;
}
.s-news__slide {
  background-color: #fff;
  margin: 0 12px;
  width: 500px;
}
@media only screen and (max-width: 1440px) {
  .s-news__slide {
    margin: 0 10px;
    width: 440px;
  }
}
@media only screen and (max-width: 600px) {
  .s-news__slide {
    width: 90vw;
  }
}
.s-news__slide-content {
  display: flex;
  flex-wrap: wrap;
  padding: 28px 32px 68px;
  height: calc(100% - 280px);
  position: relative;
}
@media only screen and (max-width: 1440px) {
  .s-news__slide-content {
    padding: 28px 28px 64px;
    height: calc(100% - 250px);
  }
}
.s-news__slide-img-wrap {
  height: 280px;
  position: relative;
  width: 100%;
  overflow: hidden;
}
.s-news__slide-img-wrap img {
  position: absolute;
  height: 100%;
  width: auto;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
@media only screen and (max-width: 1440px) {
  .s-news__slide-img-wrap {
    height: 250px;
  }
}
.s-news__slide-date {
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  color: #151515;
  margin-bottom: 12px;
}
@media only screen and (max-width: 1440px) {
  .s-news__slide-date {
    margin-bottom: 10px;
  }
}
.s-news__slide-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #151515;
  margin-bottom: 16px;
}
@media only screen and (max-width: 1440px) {
  .s-news__slide-title {
    margin-bottom: 14px;
  }
}
.s-news__slide-text {
  color: #151515;
  margin-bottom: 24px;
}
@media only screen and (max-width: 1440px) {
  .s-news__slide-text {
    margin-bottom: 18px;
  }
}
.s-news__slide-more {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #151515;
  transition: color 0.3s ease;
  position: absolute;
  bottom: 36px;
  left: 32px;
}
@media only screen and (max-width: 1440px) {
  .s-news__slide-more {
    bottom: 32px;
    left: 28px;
  }
}
.s-news__slide-more svg path {
  transition: stroke 0.3s ease;
}
.s-news__slide-more:hover {
  color: #00e3d6;
}
.s-news__slide-more:hover svg path {
  stroke: #00e3d6;
}
.s-news__img-more {
  margin-left: 22px;
}
.s-news__slider {
  position: static;
  margin-top: 20px;
}
.s-news__slider .slick-track {
  display: flex;
}
.s-news__slider .slick-list {
  overflow: visible;
  display: flex;
}
.s-news__slider .slick-arrow {
  position: absolute;
  top: 8px;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 2;
  cursor: pointer;
}
.s-news__slider .slick-arrow svg path {
  transition: stroke 0.3s ease;
}
.s-news__slider .slick-arrow.slick-disabled {
  cursor: default !important;
}
.s-news__slider .slick-arrow.slick-disabled svg path {
  stroke: #a4a4a4 !important;
}
.s-news__slider .slick-arrow:hover svg path {
  stroke: #00e3d6;
}
.s-news__slider .next-btn {
  right: 0;
}
.s-news__slider .prev-btn {
  right: 92px;
}
.s-testimonials {
  position: relative;
}
.s-testimonials:before {
  content: '';
  display: block;
  position: absolute;
  top: -50px;
  right: 0;
  width: 548px;
  height: 704px;
  background: url("/img/ellipse-star.svg") center no-repeat;
  background-size: cover;
  z-index: 1;
}
@media only screen and (max-width: 1440px) {
  .s-testimonials:before {
    width: 432px;
    height: 666px;
  }
}
@media only screen and (max-width: 1248px) {
  .s-testimonials:before {
    width: 432px;
    height: 666px;
  }
}
@media only screen and (max-width: 960px) {
  .s-testimonials:before {
    right: -140px;
  }
}
@media only screen and (max-width: 720px) {
  .s-testimonials:before {
    right: -270px;
  }
}
@media only screen and (max-width: 600px) {
  .s-testimonials:before {
    width: 260px;
    height: 360px;
    right: -140px;
  }
}
.s-testimonials__conetnt {
  position: relative;
  z-index: 2;
}
.s-testimonials__pretitle {
  text-align: center;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  line-height: 24px;
  color: rgba(255,255,255,0.65);
}
@media only screen and (max-width: 720px) {
  .s-testimonials__pretitle {
    font-size: 14px;
    line-height: 24px;
  }
}
.s-testimonials__blocks-wrap {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 24px);
  margin-left: -12px;
  margin-top: 68px;
}
.s-testimonials__block {
  margin: 12px;
  padding: 24px 24px 64px;
  background: rgba(38,38,38,0.4);
  backdrop-filter: blur(12px);
  border-radius: 0 50px 0 0;
  width: calc(100% / 4 - 24px);
  display: block;
}
@media only screen and (max-width: 1440px) {
  .s-testimonials__block {
    width: calc(100% / 3 - 24px);
  }
}
@media only screen and (max-width: 1200px) {
  .s-testimonials__block {
    width: calc(100% / 2 - 24px);
  }
}
@media only screen and (max-width: 700px) {
  .s-testimonials__block {
    width: calc(100% / 1 - 24px);
  }
}
.s-testimonials__block-header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.s-testimonials__block-img-wrap {
  width: 88px;
  height: 88px;
  margin-right: 30px;
  background: url("/img/s-testimonials-logo-bg.svg") center no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}
.s-testimonials__block-img-wrap img {
  max-width: 100%;
  margin-top: -4px;
}
@media only screen and (max-width: 960px) {
  .s-testimonials__block-img-wrap {
    margin-right: 20px;
  }
}
.s-testimonials__block-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  width: calc(100% - 118px);
  text-decoration: none;
}
.s-testimonials__block-title--link {
  transition: all 0.3s ease;
}
.s-testimonials__block-title--link:hover {
  color: #ff230a;
}
@media only screen and (max-width: 960px) {
  .s-testimonials__block-title {
    font-size: 14px;
    line-height: 24px;
  }
}
.s-testimonials__btns-wrap {
  display: flex;
  justify-content: center;
  margin-top: 68px;
}
@media only screen and (max-width: 1440px) {
  .s-testimonials__btns-wrap {
    margin-top: 32px;
  }
}
.s-testimonials.testimonial-page {
  margin-top: 0;
}
.s-testimonials.testimonial-page .s-testimonials__title {
  text-transform: none;
}
.s-testimonials.main-page-testimonials .s-testimonials__block:nth-child(n + 9) {
  display: none !important;
}
.s-contact {
  position: relative;
  margin-top: 200px;
}
@media only screen and (max-width: 1200px) {
  .s-contact {
    margin-top: 120px;
  }
}
.s-contact:before {
  content: '';
  display: block;
  width: 300px;
  height: 200px;
  position: absolute;
  right: 15%;
  top: 50%;
  transform: translateY(-50%);
  background: url("/img/s-contact-airplane.webp") center no-repeat;
  background-size: contain;
  z-index: 2;
}
@media only screen and (max-width: 1440px) {
  .s-contact:before {
    width: 200px;
  }
}
@media only screen and (max-width: 1200px) {
  .s-contact:before {
    transform: none;
    left: auto;
    right: 16%;
  }
}
@media only screen and (max-width: 720px) {
  .s-contact:before {
    right: 5%;
  }
}
@media only screen and (max-width: 600px) {
  .s-contact:before {
    width: 160px;
  }
}
.s-contact__overlay {
  content: '';
  display: block;
  height: 800px;
  width: calc(100% + 80px);
  position: absolute;
  left: 50%;
  top: -20px;
  background-color: #f4f4f4;
  transform: translateX(-50%) rotate(-2.5deg);
  z-index: 1;
  overflow: hidden;
}
@media only screen and (max-width: 1440px) {
  .s-contact__overlay {
    height: 720px;
  }
}
@media only screen and (max-width: 1200px) {
  .s-contact__overlay {
    height: 75%;
  }
}
.s-contact__overlay:after {
  content: '';
  display: block;
  width: 538px;
  height: 538px;
  position: absolute;
  top: -184px;
  right: -234px;
  border-radius: 50%;
  border: 1px solid #e9b0e5;
  opacity: 0.4;
}
@media only screen and (max-width: 1440px) {
  .s-contact__overlay:after {
    width: 400px;
    height: 400px;
    right: -100px;
    top: -100px;
  }
}
@media only screen and (max-width: 960px) {
  .s-contact__overlay:after {
    width: 300px;
    height: 300px;
    right: -150px;
  }
}
.s-contact__overlay:before {
  content: '';
  display: block;
  width: 418px;
  height: 687px;
  position: absolute;
  bottom: -300px;
  left: -20px;
  background: url("/img/s-contact-points.svg") center no-repeat;
  background-size: contain;
}
@media only screen and (max-width: 1440px) {
  .s-contact__overlay:before {
    width: 300px;
    bottom: -200px;
  }
}
@media only screen and (max-width: 1200px) {
  .s-contact__overlay:before {
    width: 260px;
  }
}
@media only screen and (max-width: 960px) {
  .s-contact__overlay:before {
    width: 200px;
  }
}
.s-contact__content {
  position: relative;
  z-index: 2;
  display: flex;
  padding-top: 40px;
  justify-content: center;
}
@media only screen and (max-width: 1440px) {
  .s-contact__content {
    padding-top: 20px;
  }
}
@media only screen and (max-width: 1200px) {
  .s-contact__content {
    flex-wrap: wrap;
    max-width: 544px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 0;
  }
}
@media only screen and (max-width: 600px) {
  .s-contact__content {
    padding-top: 40px;
  }
}
.s-contact__block--text {
  width: 45%;
}
@media only screen and (max-width: 1200px) {
  .s-contact__block--text {
    width: 100%;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 600px) {
  .s-contact__block--text {
    margin-bottom: 32px;
  }
}
.s-contact__block--form {
  width: 55%;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 1440px) {
  .s-contact__block--form {
    justify-content: center;
  }
}
@media only screen and (max-width: 1200px) {
  .s-contact__block--form {
    width: 100%;
  }
}
.s-contact__title {
  color: #151515;
  position: relative;
  z-index: 2;
  text-align: center;
}
@media only screen and (max-width: 600px) {
  .s-contact__title {
    text-align: center;
  }
}
.s-contact__pretitle {
  font-size: 16px;
  line-height: 24px;
  color: #8f95a5;
  max-width: 490px;
  position: relative;
  z-index: 2;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 600px) {
  .s-contact__pretitle {
    font-size: 14px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}
.s-contact__persons-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-top: 40px;
}
@media only screen and (max-width: 600px) {
  .s-contact__persons-wrap {
    justify-content: center;
  }
}
.s-contact__person {
  display: flex;
  align-items: flex-start;
  margin: 12px 20px;
}
@media only screen and (max-width: 1200px) {
  .s-contact__person {
    margin: 12px 20px;
  }
}
@media only screen and (max-width: 600px) {
  .s-contact__person {
    margin: 16px 20px;
    min-width: 240px;
    padding-left: 30px;
    padding-right: 30px;
  }
}
.s-contact__person-img-wrap {
  width: 120px;
  height: 120px;
  margin-right: 22px;
}
@media only screen and (max-width: 1440px) {
  .s-contact__person-img-wrap {
    width: 80px;
    height: 80px;
    margin-right: 12px;
  }
}
.s-contact__person-img-wrap img {
  max-width: 100%;
}
.s-contact__person-name {
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #151515;
}
.s-contact__person-position {
  font-weight: 600;
  font-size: 10px;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #8f95a5;
  margin-bottom: 9px;
}
.s-contact__person-socials-wrap {
  display: flex;
  align-items: center;
  width: calc(100% + 10px);
  margin-left: -5px;
}
.s-contact__person-social {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}
.s-contact__person-social svg {
  max-width: 100%;
  transition: all 0.3s ease;
}
.s-contact__person-social:hover svg {
  filter: none;
}
.s-contact__form-wrap {
  padding: 49px 65px 54px;
  background: #fff;
  box-shadow: 0px 20px 40px rgba(40,51,66,0.05);
  width: 100%;
  max-width: 635px;
  position: relative;
}
@media only screen and (max-width: 1440px) {
  .s-contact__form-wrap {
    max-width: 536px;
  }
}
@media only screen and (max-width: 600px) {
  .s-contact__form-wrap {
    padding: 29px 16px 40px;
  }
}
.s-contact__form-wrap:after {
  content: '';
  display: block;
  width: 162px;
  height: 114px;
  position: absolute;
  top: 20%;
  left: -180px;
  transform: translateY(-50%);
  background: url("/img/s-contact-airplane-2.webp") center no-repeat;
  background-size: contain !important;
  z-index: 2;
}
@media only screen and (max-width: 1440px) {
  .s-contact__form-wrap:after {
    width: 120px;
    top: 42%;
    left: -80px;
  }
}
@media only screen and (max-width: 1200px) {
  .s-contact__form-wrap:after {
    top: 15%;
  }
}
@media only screen and (max-width: 600px) {
  .s-contact__form-wrap:after {
    display: none;
  }
}
.s-contact__form-wrap:before {
  content: '';
  display: block;
  width: 272px;
  height: 272px;
  position: absolute;
  top: 18%;
  left: -80px;
  border-radius: 50%;
  border: 1px solid #e9b0e5;
  z-index: -1;
  opacity: 0.4;
}
@media only screen and (max-width: 1440px) {
  .s-contact__form-wrap:before {
    width: 200px;
    height: 200px;
    left: -40px;
    top: 25%;
  }
}
@media only screen and (max-width: 1200px) {
  .s-contact__form-wrap:before {
    top: 10%;
  }
}
@media only screen and (max-width: 720px) {
  .s-contact__form-wrap:before {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  .s-contact__form-wrap:before {
    top: -120px;
    left: -100px;
  }
}
.s-contact__form-title {
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.5px;
  color: #151515;
  margin-bottom: 32px;
  text-align: center;
}
@media only screen and (max-width: 600px) {
  .s-contact__form-title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 22px;
  }
}
.s-contact__form-title span {
  color: #00e3d6;
}
.s-contact__socials-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 36px;
  width: calc(100% + 24px);
  margin-left: -12px;
}
.s-contact__social {
  margin: 12px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.s-contact__social img {
  max-width: 100%;
}
.s-contact__form-wrap:after {
  opacity: 0 !important;
  transform: translateX(100%) !important;
  transition: all 0.3s ease;
}
.s-contact:before {
  opacity: 0 !important;
  transform: translateX(-100%) !important;
  transition: all 0.3s ease;
}
.s-contact.seen:before {
  opacity: 1 !important;
  transform: none !important;
}
.s-contact.seen .s-contact__form-wrap:after {
  opacity: 1 !important;
  transform: none !important;
}
.s-partners {
  margin: 0;
  padding: 30px 0;
}
.s-partners__content {
  display: flex;
  align-items: center;
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}
.s-partners__content::-webkit-scrollbar {
  display: none;
}
.s-partners__logos-wrap {
  display: flex;
  align-items: center;
}
.s-partners__title {
  margin-right: 40px;
  font-size: 34px;
  line-height: 46px;
  letter-spacing: -1px;
  font-family: 'Helvetica Neue';
  color: #fff;
  font-weight: 900;
}
@media only screen and (max-width: 720px) {
  .s-partners__title {
    font-size: 24px;
    line-height: 32px;
  }
}
.s-partners__logo {
  margin: 20px;
  opacity: 0.45;
  transition: ease 0.3s ease;
  max-height: 40px;
}
@media only screen and (max-width: 1440px) {
  .s-partners__logo {
    margin: 20px 16px;
  }
}
@media only screen and (max-width: 720px) {
  .s-partners__logo {
    margin: 20px 12px;
  }
}
.s-partners__logo:last-child {
  margin-right: 0;
}
.footer__content {
  padding-top: 80px;
  padding-bottom: 110px;
  border-top: 1px solid #3a3b40;
  display: flex;
  justify-content: space-between;
  width: calc(100% + 24px);
  margin-left: -12px;
}
@media only screen and (max-width: 960px) {
  .footer__content {
    flex-wrap: wrap;
  }
}
.footer__logo-wrap {
  margin-bottom: 32px;
}
.footer__logo-wrap img {
  width: 168px;
}
.footer__copyright {
  margin-top: 16px;
}
@media only screen and (max-width: 700px) {
  .footer__col-content-block {
    width: 50%;
  }
}
@media only screen and (max-width: 430px) {
  .footer__col-content-block {
    width: 100%;
  }
}
.footer__col {
  margin: 0 12px;
}
@media only screen and (max-width: 960px) {
  .footer__col:nth-child(3),
  .footer__col:nth-child(2) {
    width: calc(25% - 24px);
  }
  .footer__col:nth-child(4) {
    display: flex;
    justify-content: space-between;
    width: calc(50% - 24px);
  }
}
@media only screen and (max-width: 700px) {
  .footer__col:nth-child(3),
  .footer__col:nth-child(2) {
    width: calc(50% - 24px);
  }
  .footer__col:nth-child(4) {
    width: calc(100% - 24px);
  }
}
@media only screen and (max-width: 430px) {
  .footer__col {
    width: 100% !important;
  }
}
.footer__col--text {
  max-width: 762px;
}
@media only screen and (max-width: 1440px) {
  .footer__col--text {
    max-width: 415px;
  }
}
@media only screen and (max-width: 960px) {
  .footer__col--text {
    width: 100%;
    max-width: inherit;
    order: 2;
    margin-top: 24px;
  }
}
.footer__links-title {
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
}
.footer__links-wrap {
  margin-bottom: 38px;
}
.footer__link-wrap {
  margin-bottom: 12px;
}
.footer__link {
  font-size: 14px;
  line-height: 24px;
  color: #8f95a5;
  transition: color 0.3s ease;
}
.footer__link:hover {
  color: #00e3d6;
}
.footer__socials-wrap {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 16px);
  margin-left: -8px;
}
@media only screen and (max-width: 1440px) {
  .footer__socials-wrap {
    max-width: 160px;
  }
}
.footer__social-link {
  margin: 8px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer__social-link svg {
  max-width: 100%;
  opacity: 0.45;
  transition: all 0.3s ease;
}
.footer__social-link svg path {
  transition: all 0.3s ease;
}
.footer__social-link:hover svg {
  opacity: 1;
}
.footer__social-link:hover svg path {
  fill: #00e3d6;
}
.footer .footer__logo--black {
  display: none;
}
.s-news-widget {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  padding: 8px;
  background-color: #fc311d;
  margin: 0;
}
.s-news-widget__text {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
}
@media only screen and (max-width: 600px) {
  .s-news-widget__text {
    width: 100%;
    margin-bottom: 12px;
  }
}
.s-news-widget__btn {
  margin-left: 30px;
  padding: 6px 12px;
}
@media only screen and (max-width: 600px) {
  .s-news-widget__btn {
    margin-left: 0;
  }
}
.s-achievements-2 {
  position: relative;
  padding-top: 72px;
  padding-bottom: 72px;
  background: rgba(15,14,12,0.7);
  z-index: 4;
  margin-top: 0;
}
@media only screen and (max-width: 600px) {
  .s-achievements-2 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.s-achievements-2__img-bg {
  position: absolute;
  width: 498px;
  right: 42px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
@media only screen and (max-width: 1670px) {
  .s-achievements-2__img-bg {
    right: -140px;
  }
}
@media only screen and (max-width: 1248px) {
  .s-achievements-2__img-bg {
    right: -200px;
  }
}
@media only screen and (max-width: 960px) {
  .s-achievements-2__img-bg {
    width: 405px;
    top: -160px;
    transform: none;
    right: -100px;
  }
}
@media only screen and (max-width: 720px) {
  .s-achievements-2__img-bg {
    right: -200px;
  }
}
@media only screen and (max-width: 600px) {
  .s-achievements-2__img-bg {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.2;
    z-index: -1;
  }
}
.s-achievements-2__content {
  position: relative;
  z-index: 2;
}
.s-achievements-2__blocks-wrap {
  display: flex;
  justify-content: space-between;
  max-width: 1054px;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 1440px) {
  .s-achievements-2__blocks-wrap {
    max-width: 880px;
  }
}
@media only screen and (max-width: 600px) {
  .s-achievements-2__blocks-wrap {
    justify-content: center;
    flex-wrap: wrap;
  }
}
.s-achievements-2__block {
  width: calc(100% / 3 - 48px);
  margin: 24px;
}
@media only screen and (max-width: 600px) {
  .s-achievements-2__block {
    width: calc(100% / 2 - 10px);
    margin: 20px 5px;
  }
}
.s-achievements-2__block-img-wrap {
  width: 100px;
  height: 100px;
  margin-bottom: 8px;
  margin-left: auto;
  margin-right: auto;
}
.s-achievements-2__block-title {
  font-weight: 800;
  font-size: 80px;
  line-height: 72px;
  text-align: center;
  letter-spacing: -3px;
  color: #ff230a;
  margin-bottom: 16px;
  font-family: 'Helvetica Neue';
}
@media only screen and (max-width: 720px) {
  .s-achievements-2__block-title {
    font-size: 64px;
    line-height: 64px;
  }
}
.s-achievements-2__block-text {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.9px;
  text-transform: capitalize;
  color: rgba(255,255,255,0.65);
}
.s-faq {
  padding-top: 0;
  position: relative;
  margin-top: 60px;
}
@media only screen and (max-width: 720px) {
  .s-faq {
    margin-top: 32px;
  }
}
.s-faq:before {
  content: '';
  display: block;
  width: 532px;
  height: 532px;
  position: absolute;
  background: url("/img/ellipse-green.svg") center no-repeat;
  background-size: cover;
  left: 32px;
  bottom: -150px;
  opacity: 0.4;
}
@media only screen and (max-width: 1248px) {
  .s-faq:before {
    display: none;
  }
}
.s-faq:after {
  content: '';
  display: block;
  width: 687px;
  height: 554px;
  position: absolute;
  right: -100px;
  top: -100px;
  background: url("/img/s-faq-message-img.webp") center no-repeat;
  background-size: contain;
}
@media only screen and (max-width: 1440px) {
  .s-faq:after {
    display: none;
  }
}
.s-faq__content {
  position: relative;
  z-index: 2;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
}
.s-faq__title {
  text-transform: none;
  text-align: center;
}
.s-faq__description {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #fff;
  opacity: 0.65;
  max-width: 502px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 78px;
}
@media only screen and (max-width: 600px) {
  .s-faq__description {
    margin-bottom: 36px;
  }
}
.s-faq__collapse-block {
  margin-top: 48px;
}
.s-faq__collapse-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #fff;
  margin-bottom: 28px;
}
.s-faq__collapse {
  background-color: transparent;
  box-shadow: none;
  border: none;
}
.s-faq__collapse a {
  color: #00e3d6;
}
.s-faq__collapse .collapsible-item {
  margin-bottom: 8px;
}
.s-faq__collapse .collapsible-item.active .collapsible-header {
  color: #27272d;
  background-color: #fff;
}
.s-faq__collapse .collapsible-item.active .collapsible-header:after {
  display: none;
}
.s-faq__collapse .collapsible-header {
  padding: 20px 16px 20px 56px;
  position: relative;
  background-color: #202020;
  box-shadow: none;
  border: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: rgba(255,255,255,0.65);
  transition: all 0.3s ease;
}
.s-faq__collapse .collapsible-header:before {
  content: '';
  display: block;
  left: 22px;
  top: 30px;
  height: 2px;
  width: 14px;
  background-color: #00e3d6;
  position: absolute;
  border-radius: 2px;
}
.s-faq__collapse .collapsible-header:after {
  content: '';
  display: block;
  left: 28px;
  top: 24px;
  height: 14px;
  width: 2px;
  background-color: #00e3d6;
  position: absolute;
  border-radius: 2px;
}
.s-faq__collapse .collapsible-body {
  padding: 24px 16px 24px 56px;
  background-color: transparent;
  box-shadow: none;
  border: none;
  font-size: 16px;
  line-height: 24px;
  color: rgba(255,255,255,0.65);
}
@media only screen and (max-width: 960px) {
  .s-faq__collapse .collapsible-body {
    padding-left: 44px;
  }
}
@media only screen and (max-width: 720px) {
  .s-faq__collapse .collapsible-body {
    padding-left: 36px;
  }
}
@media only screen and (max-width: 600px) {
  .s-faq__collapse .collapsible-body {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.modal-choose-signup {
  background-color: #fff;
  padding-top: 60px;
  max-width: 480px;
}
@media only screen and (max-width: 600px) {
  .modal-choose-signup {
    margin: 0;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
  }
}
.modal-choose-signup .modal__cross {
  width: 48px;
  height: 48px;
  right: 24px;
  top: 24px;
}
.modal-choose-signup__title {
  color: #27272d;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
}
.modal-choose-signup__title span {
  color: #00e3d6;
}
.modal-choose-signup__selectors-wrap {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 16px);
  margin-left: -8px;
}
.modal-choose-signup__selector {
  border: 1px solid rgba(255,255,255,0.06);
  background: rgba(21,21,21,0.04);
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all 0.3s ease;
  margin: 12px 8px;
  width: calc(100% - 16px);
  cursor: pointer;
  height: 80px;
}
.modal-choose-signup__selector.active {
  border: 1px solid #01d5c9;
}
.modal-choose-signup__selector.active .modal-choose-signup__selector-check {
  opacity: 1;
}
.modal-choose-signup__selector-check {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 4px;
  top: 4px;
  transition: all 0.3s ease;
  opacity: 0.02;
}
.modal-choose-signup__btns-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 32px;
}
.modal-choose-signup__btns-wrap .btn-custom {
  display: none;
  font-size: 16px;
  line-height: 20px;
  padding: 12px;
  width: 100%;
}
.modal-choose-signup__btns-wrap .btn-custom.active {
  display: flex;
}
.modal-choose-signup__bottom-text {
  color: rgba(21,21,21,0.5);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}
.modal-choose-signup__bottom-text a {
  color: #00c3b8;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
}
